import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/partner/dashboard',
      name: 'partner-dashboard',
      component: () => import('@/views/Partner.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/member/lobby',
      name: 'lobby',
      component: () => import('@/views/Home.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'หน้าแรก',
            active: true
          }
        ]
      }
    },
    {
      path: '/member/deposit',
      name: 'deposit',
      component: () => import('@/views/Deposit.vue'),
      meta: {
        pageTitle: 'ฝาก',
        breadcrumb: [
          {
            text: 'ฝาก'
          }
        ]
      }
    },
    {
      path: '/member/withdraw',
      name: 'withdraw',
      component: () => import('@/views/Withdraw.vue'),
      meta: {
        pageTitle: 'ถอน',
        breadcrumb: [
          {
            text: 'ถอน'
          }
        ]
      }
    },
    {
      path: '/member/promotion',
      name: 'promotion',
      component: () => import('@/views/Promotion.vue'),
      meta: {
        pageTitle: 'โปรโมชั่น',
        breadcrumb: [
          {
            text: 'โปรโมชั่น'
          }
        ]
      }
    },
    {
      path: '/member/cashback',
      name: 'cashback',
      component: () => import('@/views/Cashback.vue'),
      meta: {
        pageTitle: 'รับเครดิตเงินคืน',
        breadcrumb: [
          {
            text: 'รับเครดิตเงินคืน'
          }
        ]
      }
    },
    {
      path: '/member/setting',
      name: 'setting',
      component: () => import('@/views/Setting.vue'),
      meta: {
        pageTitle: 'ตั้งค่า',
        breadcrumb: [
          {
            text: 'ตั้งค่า'
          }
        ]
      }
    },
    {
      path: '/gameplay',
      name: 'gameplay',
      component: () => import('@/views/Gameplay.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/gameplay/casino',
      name: 'casino',
      component: () => import('@/views/GameplayCasino.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/gameplay/slot_provider',
      name: 'casino',
      component: () => import('@/views/GameplaySlotProvider.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/gameplay/slots',
      name: 'slot',
      component: () => import('@/views/GameplaySlot.vue')
    },
    {
      path: '/gameplay/sport',
      name: 'sport',
      component: () => import('@/views/GameplaySport.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/gameplay/login',
      name: 'casinoLogin',
      component: () => import('@/views/GameplayLogin.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/gameplay/login/slot',
      name: 'slotLogin',
      component: () => import('@/views/GameplayLoginSlot.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: '/member/lobby'
    }
  ]
})

export default router
